import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import styled from 'styled-components'
import Cale from "../../content/assets/cale1.jpg"

class AboutMe extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const AboutWrapper = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      /*width: 80%;
      height: 80%;*/
    `;

    const AboutItems = styled.div`
      width: 100%;
      height: 100%;
      /*padding: 1em;*/
      margin: .5em;
      font-family: Montserrat, sans-serif;
    `;

    return (
      <Layout location={`/about-me`} title={siteTitle}>
        <SEO title="About Me" />
        <AboutWrapper>
          <AboutItems>
            <p>Why hello there!</p>
            <p>I'm just a dude trying to carve out a little space out here on the far reaches of the internet.</p>
            <p>Have a real of the ol' blog, check out some things <a target="_blank" href="https://www.youtube.com/watch?v=ZiS3YA5nwcw">I dun did</a>
              , and maybe chat in to yer boi.
            </p>
            <img src={Cale} />
          </AboutItems>
        </AboutWrapper>
        
      </Layout>
    )
  }
}

export default AboutMe

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
// 
// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allContentfulPost {
//       edges {
//         node {
//           title
//           subtitle
//           author
//           slug
//           updatedAt
//         }
//       }
//     }
//   }
// `
